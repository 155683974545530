import "../individual.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Item = ({ company }) => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  const dispatch = useDispatch();
  const openCardPopup = useSelector((state) => state.card.openCardPopup);

  const handleOpen = () => {
    dispatch({ type: "openCard", payload: true });
  };

  const handleClose = () => {
    dispatch({ type: "closeCard", payload: false });
  };

  return (
    <div className="individual__item">
      <div className="individual__row">
        <div className="individual__column">
          <ul className="individual__col-texts">
            <h4 className="individual__col-product">{company.product}</h4>
            <li className="individual__col-text">Заказчик: {company.buyer}</li>
            <li className="individual__col-text">Город доставки: {company.city}</li>
            <li className="individual__col-text">Страна производителя: {company.country}</li>
            <li className="individual__col-text">Срок доставки: {company.time}</li>
            <li className="individual__col-text">Итоговая стоимость: {company.cost}</li>
          </ul>
          <button
            className={openCardPopup ? "individual__button individual__button_hidden" : "individual__button"}
            type="button"
            onClick={handleOpen}>
            Подробнее
          </button>
        </div>
        <div className="individual__column">
          <img src={company.image} alt="фото" className="individual__image" />
        </div>
      </div>
      {openCardPopup ? (
        <>
          <div className="individual__row individual__row_opened">
            <div className="individual__column individual__column_bottom">
              <h6 className="individual__col-subtitle">Особенности заказа</h6>
              <p className="individual__col-text">{company.features}</p>
            </div>
            <div className="individual__column individual__column_bottom">
              <h6 className="individual__col-subtitle">Результат</h6>
              <p className="individual__col-text">{company.result}</p>
            </div>
          </div>
          <button
            className={
              !openCardPopup ? "individual__button-close individual__button_hidden" : "individual__button-close"
            }
            type="button"
            onClick={handleClose}>
            Скрыть
          </button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Item;
