import SaleSvg from "../svg-images/second/saleSvg";
import { Form } from "../form/form";
import list from "../../images/icons/list.svg";
import "./seller.css";
import { useSelector } from "react-redux";
const Seller = ({ setIsLoading }) => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <section className="seller">
      <h2 className="seller__title">ВАША ЭКОНОМИЯ ОКОЛО 40%</h2>
      <h5 className="seller__subtitle">На основе нашего внутреннего анализа всех поставок за прошедшие 3 года</h5>
      <div className="seller__container">
        <div className="seller__column">
          <div className="seller__image">
            <SaleSvg />
          </div>
          {pageWidth > 767 ? (
            <>
              <div className="seller__absolute seller__absolute1">
                <h6 className="seller__text-title">Выгодно</h6>
                <p className="seller__text-text">Цены от поставщика без накрутки</p>
              </div>
              <div className="seller__absolute seller__absolute2">
                <h6 className="seller__text-title">Надежно</h6>
                <p className="seller__text-text">Все сроки строго прописаны в договоре</p>
              </div>
              <div className="seller__absolute seller__absolute3">
                <h6 className="seller__text-title">Прозрачно</h6>
                <p className="seller__text-text">
                  Все переговоры с поставщиком ведутся в общем чате под вашим контролем
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="seller__list">
                <img src={list} alt="list" className="seller__li" />
                <div className="seller__absolute seller__absolute1">
                  <h6 className="seller__text-title">Выгодно</h6>
                  <p className="seller__text-text">Цены от поставщика без накрутки</p>
                </div>
              </div>
              <div className="seller__list">
                <img src={list} alt="list" className="seller__li" />
                <div className="seller__absolute seller__absolute2">
                  <h6 className="seller__text-title">Надежно</h6>
                  <p className="seller__text-text">Все сроки строго прописаны в договоре</p>
                </div>
              </div>
              <div className="seller__list">
                <img src={list} alt="list" className="seller__li" />
                <div className="seller__absolute seller__absolute3">
                  <h6 className="seller__text-title">Прозрачно</h6>
                  <p className="seller__text-text">
                    Все переговоры с поставщиком ведутся в общем чате под вашим контролем
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="seller__form" id="form-seller">
          <Form setIsLoading={setIsLoading} />
        </div>
      </div>
    </section>
  );
};

export default Seller;
