import "./thirdLines.css";
import { useSelector } from "react-redux";
const Line2 = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div className="third__line third__line2">
      {pageWidth >= 1440 ? (
        <svg width="139" height="187" viewBox="0 0 139 187" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M139 186.5H1V2H20.5161" stroke="#2FBAE5" />
          <circle cx="20.5" cy="2.5" r="2.5" fill="#2FBAE5" />
        </svg>
      ) : (
        <svg width="206" height="230" viewBox="0 0 206 230" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 91.5H0V91.7663L0.221013 91.9149L0.5 91.5ZM0.5 3.50049V3.00049H0V3.50049H0.5ZM47.3333 3.50049C47.3333 4.97325 48.5272 6.16716 50 6.16716C51.4728 6.16716 52.6667 4.97325 52.6667 3.50049C52.6667 2.02773 51.4728 0.833822 50 0.833822C48.5272 0.833822 47.3333 2.02773 47.3333 3.50049ZM1 91.5V3.50049H0V91.5H1ZM0.5 4.00049H50V3.00049H0.5V4.00049ZM0.221013 91.9149L204.721 229.415L205.279 228.585L0.778987 91.0851L0.221013 91.9149Z"
            fill="#2FBAE5"
          />
        </svg>
      )}
    </div>
  );
};

export default Line2;
