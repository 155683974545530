const defaultStateNumberPopup = { openNumberPopup: false };

export const openNumberPopupReducer = (state = defaultStateNumberPopup, action) => {
  switch (action.type) {
    case "openNumberPopup":
      return { ...state, openNumberPopup: action.payload };
    case "closeNumberPopup":
      return { ...state, openNumberPopup: action.payload };
    default:
      return state;
  }
};
