import "./lines.css";
import { useSelector } from "react-redux";
const Line2 = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div className="line line2">
      {pageWidth >= 1440 ? (
        <svg width="193" height="119" viewBox="0 0 193 119" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M105.277 118.495L105.777 118.493L105.772 117.493L105.272 117.495L105.277 118.495ZM147.309 3.00009H189.345V2.00009H147.309V3.00009ZM147.309 1.99982L104.932 117.631L105.871 117.975L148.248 2.34393L147.309 1.99982ZM0.185995 119L105.277 118.495L105.272 117.495L0.181192 118L0.185995 119Z"
            fill="#2FBAE5"
          />
          <circle cx="190.5" cy="2.5" r="2.5" fill="#2FBAE5" />
        </svg>
      ) : (
        <svg width="228" height="113" viewBox="0 0 228 113" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M73.5 3V2.5H73.1156L73.0168 2.87144L73.5 3ZM44.5 112V112.5H44.8844L44.9832 112.129L44.5 112ZM222.333 3C222.333 4.47276 223.527 5.66667 225 5.66667C226.473 5.66667 227.667 4.47276 227.667 3C227.667 1.52724 226.473 0.333333 225 0.333333C223.527 0.333333 222.333 1.52724 222.333 3ZM73.0168 2.87144L44.0168 111.871L44.9832 112.129L73.9832 3.12856L73.0168 2.87144ZM73.5 3.5H225V2.5H73.5V3.5ZM44.5 111.5H0V112.5H44.5V111.5Z"
            fill="#2FBAE5"
          />
        </svg>
      )}
    </div>
  );
};

export default Line2;
