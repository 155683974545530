const defaultIndexFile = { index: 0 };

export const indexFileReduser = (state = defaultIndexFile, action) => {
  switch (action.type) {
    case "add_index":
      return { ...state, index: state.index + action.payload };
    case "set_index":
      return { ...state, index: action.payload };
    default:
      return state;
  }
};
