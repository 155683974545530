import "./individual.css";
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { data } from "../../utils/individual-data";
import { useSelector } from "react-redux";
import Item from "./item/item";
import nextArrow from "../../images/icons/nextArrow.svg";
import prevArrow from "../../images/icons/prevArrow.svg";
import "./individual.css";

function SampleNextArrow(props) {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: `${pageWidth >= 1440 ? "flex" : "none"}`,
        alignItems: "center",
        justifyContent: "center",
        content: "",
        width: "100px",
        height: "100px",
        backgroundImage: `url(${nextArrow})`,
        zIndex: "2",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: `${pageWidth >= 1440 ? "flex" : "none"}`,
        alignItems: "center",
        justifyContent: "center",
        width: "100px",
        height: "100px",
        backgroundImage: `url(${prevArrow})`,
        zIndex: "2",
      }}
      onClick={onClick}
    />
  );
}

class Individual extends Component {
  render() {
    const settings = {
      dots: true,
      dotsClass: "slick-dots individual__dots",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <section className="individual">
        <div className="individual__container">
          <h2 className="individual__title">Инвивидуальный подход к каждой поставке</h2>
          <h5 className="individual__subtitle">Примеры доставленного оборудования</h5>
          <div className="individual__slider">
            <Slider {...settings}>
              {data.map((company) => (
                <Item company={company} key={company.id} />
              ))}
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}

export default Individual;
