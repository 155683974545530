import "./anotherPages.css";

function PersonalData() {
  return (
    <section className="personal" data-testid="personal">
      <div className="policy__container">
        <h1 className="policy__title">СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
        <p className="policy__text">
          Вы (далее - Пользователь), оставляя заявку на данном интернет-сайте: maximum-logistics.ru (далее - Сайт),
          принимаете настоящее Согласие на обработку персональных данных (далее - Согласие). Действуя свободно, своей
          волей и в своем интересе, а также подтверждая свою дееспособность, Пользователь дает свое согласие Обществу с
          ограниченной ответственностью (далее – ООО «Максимум») (ИНН 7811751233, ОГРН 1207800123200), который
          расположен по адресу Санкт-Петербург, ул. Ольги Берггольц, д 36, офис 1, на обработку своих персональных
          данных со следующими условиями:
        </p>
        <p className="policy__text">
          1. Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и
          с их использованием.
        </p>
        <p className="policy__text">2. Согласие дается на обработку следующих персональных данных Пользователя:</p>
        <p className="policy__text">
          2.1. Персональные данные, не являющиеся специальными или биометрическими: имена, номера контактных телефонов;
          адреса электронной̆ почты; пользовательские данные (сведения о местоположении; тип и версия ОС; тип и версия
          Браузера; тип устройства и разрешение его экрана; источник, откуда пришел на сайт пользователь; с какого сайта
          или по какой рекламе; язык ОС и Браузера; какие страницы открывает и на какие кнопки нажимает пользователь;
          ip-адрес).
        </p>
        <p className="policy__text">3. Персональные данные не являются общедоступными.</p>
        <p className="policy__text">
          4. Цель обработки персональных данных: обработка входящих запросов физических лиц с целью оказания услуг;
          аналитика действий физического лица на сайте и функционирования сайта; проведение рекламных и новостных
          рассылок.
        </p>
        <p className="policy__text">
          5. Основанием для обработки персональных данных является: ст. 24 Конституции Российской Федерации; ст. 6
          Федерального закона №152-ФЗ «О персональных данных»; настоящее согласие на обработку персональных данных.
        </p>
        <p className="policy__text">
          6. В ходе обработки с персональными данными будут совершены следующие действия: сбор; запись; систематизация;
          накопление; хранение; уточнение (обновление, изменение); извлечение; использование; передача (распространение,
          предоставление, доступ); блокирование; удаление; уничтожение.
        </p>
        <p className="policy__text">
          7. Персональные данные обрабатываются до отписки физического лица от рекламных и новостных рассылок. Также
          обработка персональных данных может быть прекращена по запросу субъекта персональных данных.
        </p>
        <p className="policy__text">
          7.1. Согласие может быть отозвано субъектом персональных данных или его представителем путем направления
          письменного заявления на бумажном носителе ООО «Максимум». по адресу, указанному в начале данного Согласия.
          Отказ можно также направить в форме электронного документа, подписанного квалифицированной электронной
          подписью в соответствии с законодательством РФ. В случае отказа пользователя получать предложения по
          электронной почте, ООО «Максимум». оставляет за собой право связаться с пользователем для решения деловых
          вопросов или предоставления важной информации. Связь с компанией ООО «Максимум». по электронной почте не
          гарантирует надежной передачи данных. В процессе доставки информация может стать известна третьей стороне. ООО
          «Максимум». не несет ответственности за ее разглашение в результате ошибок или несанкционированных действий со
          стороны третьего лица, а так же за прямые и косвенно причиненные ущербы.
        </p>
        <p className="policy__text">
          8. В случае отзыва субъектом персональных данных или его представителем согласия на обработку персональных
          данных ООО «Максимум». вправе продолжить обработку персональных данных без согласия субъекта персональных
          данных при наличии оснований, указанных в пунктах 2 - 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи
          11 Федерального закона №152-ФЗ «О персональных данных» от 27.07.2006 г.
        </p>
        <p className="policy__text">
          9. Настоящее согласие действует все время до момента прекращения обработки персональных данных, указанных в
          п.7 и п.8 данного Согласия.
        </p>
        <p className="policy__text">
          10. Если в соответствии с действующими законами какие-либо условия будут признаны недействительными, остальные
          условия остаются в полной силе. В случае несогласия с этими условиями, Пользователь должен воздержаться от
          использования данного Сайта и Сервисов.
        </p>
      </div>
    </section>
  );
}

export default PersonalData;
