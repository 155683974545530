import "./lines.css";
import { useSelector } from "react-redux";
const Line1 = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div className="line line1">
      {pageWidth >= 1440 ? (
        <svg width="63" height="193" viewBox="0 0 63 193" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 2.5H27.0233M1 191.5H62.0526M0.5 3V192.011" stroke="#2FBAE5" />
          <circle cx="26.5" cy="2.5" r="2.5" fill="#2FBAE5" />
        </svg>
      ) : (
        <svg width="131" height="173" viewBox="0 0 131 173" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 172.5H0V173H0.5V172.5ZM0.5 3.50049V3.00049H0V3.50049H0.5ZM9.33333 3.50049C9.33333 4.97325 10.5272 6.16716 12 6.16716C13.4728 6.16716 14.6667 4.97325 14.6667 3.50049C14.6667 2.02773 13.4728 0.833822 12 0.833822C10.5272 0.833822 9.33333 2.02773 9.33333 3.50049ZM131 172H0.5V173H131V172ZM1 172.5V3.50049H0V172.5H1ZM0.5 4.00049H12V3.00049H0.5V4.00049Z"
            fill="#2FBAE5"
          />
        </svg>
      )}
    </div>
  );
};

export default Line1;
