import { combineReducers, createStore } from "redux";
import { openReducer } from "./openReducer";
import { pageWidthReducer } from "./pageWidthReducer";
import { successReducer } from "./successReducer";
import { openCardReducer } from "./openCardReducer";
import { indexFileReduser } from "./indexFileReducer";
import { openScreenReducer } from "./openScreenReducer";
import { screenSrcReducer } from "./screenSrcReducer";
import { openNumberPopupReducer } from "./openNumberPopupReducer";

const rootReducer = combineReducers({
  open: openReducer,
  success: successReducer,
  width: pageWidthReducer,
  card: openCardReducer,
  index: indexFileReduser,
  screen: openScreenReducer,
  src: screenSrcReducer,
  number: openNumberPopupReducer,
});

export const store = createStore(rootReducer);
