import { useSelector } from "react-redux";
import "./title.css";

const Title = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <section className="title">
      <div className="title__container">
        {pageWidth >= 1439 ? (
          <>
            <h3 className="title__subtitle">Организуем под ключ закупку любого</h3>
            <h1 className="title__title">промышленного оборудования</h1>
            <h5 className="title__countries">Китай / Тайвань / Южная Корея</h5>
            <div className="title__sale">
              <p className="title__sale-text">за 10% от стоимости контракта</p>
            </div>
          </>
        ) : (
          <>
            <h1 className="title__title">Импорт промышленного оборудования</h1>
            <h5 className="title__countries">Из Китая, Тайваня и Южной Кореи за 10% от стоимости контракта</h5>
          </>
        )}
      </div>
    </section>
  );
};

export default Title;
