import Map from "../map/map";
import { useDispatch } from "react-redux";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  const dispatch = useDispatch();
  const setPopupOpened = () => {
    dispatch({ type: "openNumberPopup", payload: true });
  };
  return (
    <footer className="footer" itemScope itemType="http://schema.org/Organization">
      <div className="footer__container">
        <div className="footer__two-column">
          <div className="footer__left-column">
            <div className="footer__column">
              <h2 className="footer__title">КОНТАКТЫ</h2>
              <div>
                <p className="footer__text" itemProp="address">
                  192148, Россия, г. Санкт-Петербург,
                </p>
                <p className="footer__text" itemProp="address">
                  ул. Ольги Берггольц, д.36, стр. 2, офис 1
                </p>
              </div>
              <p className="footer__email" itemProp="email">
                order@maximum-logistics.ru
              </p>
              <a href="tel:+78122004643" className="header__link footer__link_phone">
                <h6>+ 7 812 200-46-43</h6>
              </a>
            </div>
            <div className="footer__mes">
              <div className="footer__button" onClick={setPopupOpened}>
                <Button type="button" typeButton="primary">
                  <p className="header__button-text">Перезвоните мне</p>
                </Button>
              </div>
              <a href="tg://resolve?domain=maximum_import" className="footer__link">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 10C0 4.47715 4.47715 0 10 0H30C35.5228 0 40 4.47715 40 10V30C40 35.5228 35.5228 40 30 40H10C4.47715 40 0 35.5228 0 30V10Z"
                    fill="#039BE5"
                    className="header__tg-color"
                  />
                  <path
                    d="M10.3456 18.6054L27.2702 12.0799C28.0557 11.7961 28.7418 12.2715 28.4872 13.4593L28.4887 13.4578L25.607 27.034C25.3934 27.9965 24.8215 28.2306 24.0213 27.7771L19.6329 24.5429L17.5163 26.582C17.2822 26.8161 17.0847 27.0135 16.6313 27.0135L16.9429 22.5476L25.076 15.2C25.43 14.8884 24.997 14.7129 24.5304 15.023L14.4795 21.3511L10.1467 19.9994C9.20612 19.701 9.18564 19.0589 10.3456 18.6054Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a href="https://wa.me/79995241046" className="footer__link">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 10C0 4.47715 4.47715 0 10 0H30C35.5228 0 40 4.47715 40 10V30C40 35.5228 35.5228 40 30 40H10C4.47715 40 0 35.5228 0 30V10Z"
                    fill="#1BD741"
                    className="header__wa-color"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.1384 12.9065C25.2446 11.0332 22.726 10.0011 20.0428 10C14.5139 10 10.0141 14.4457 10.0119 19.9098C10.0111 21.6565 10.473 23.3616 11.3508 24.8645L9.92773 30L15.2453 28.6218C16.7105 29.4115 18.3601 29.8276 20.0388 29.8281H20.043C25.5713 29.8281 30.0716 25.3821 30.0737 19.9177C30.0748 17.2695 29.0324 14.7797 27.1384 12.9065ZM20.0428 28.1544H20.0394C18.5434 28.1538 17.0762 27.7566 15.796 27.0062L15.4917 26.8276L12.3361 27.6455L13.1784 24.6058L12.9801 24.2942C12.1455 22.9827 11.7048 21.4668 11.7055 19.9104C11.7073 15.3688 15.4474 11.6738 20.0462 11.6738C22.2731 11.6746 24.3664 12.5325 25.9405 14.0895C27.5145 15.6465 28.3809 17.7161 28.3801 19.9171C28.3782 24.4591 24.6382 28.1544 20.0428 28.1544ZM24.616 21.9851C24.3654 21.8611 23.1331 21.2622 22.9033 21.1794C22.6738 21.0968 22.5065 21.0557 22.3395 21.3035C22.1724 21.5513 21.6921 22.1091 21.5458 22.2743C21.3996 22.4396 21.2535 22.4603 21.0028 22.3363C20.7521 22.2124 19.9446 21.9508 18.9872 21.1072C18.2422 20.4506 17.7392 19.6396 17.5929 19.3918C17.4469 19.1438 17.5917 19.0227 17.7029 18.8865C17.9742 18.5536 18.2459 18.2046 18.3294 18.0394C18.4131 17.8741 18.3712 17.7295 18.3084 17.6056C18.2459 17.4817 17.7446 16.2628 17.5358 15.7668C17.3322 15.2842 17.1258 15.3494 16.9718 15.3418C16.8258 15.3346 16.6586 15.3331 16.4914 15.3331C16.3244 15.3331 16.0528 15.395 15.823 15.6431C15.5933 15.891 14.9459 16.49 14.9459 17.7089C14.9459 18.9277 15.844 20.1052 15.9693 20.2705C16.0946 20.4358 17.7367 22.937 20.2509 24.0095C20.8489 24.2649 21.3157 24.4171 21.6798 24.5312C22.2802 24.7197 22.8265 24.6931 23.2584 24.6294C23.74 24.5582 24.7412 24.0303 24.9502 23.4519C25.159 22.8734 25.159 22.3777 25.0962 22.2743C25.0337 22.171 24.8666 22.1091 24.616 21.9851Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="footer__column">
            <div className="footer__block">
              <Map />
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyright-container">
        <p className="footer__copyright">
          © 2015- 2023 ООО{" "}
          <span itemProp="legalName">«Максимум» — Организация импорта для промышленных предприятий</span>
        </p>
        <p className="footer__copyright">
          Сайт носит исключительно информационный характер и никакая информация, опубликованная на нём, ни при каких
          условиях не является публичной офертой, определяемой положениями пункта 2 статьи 437 Гражданского кодекса
          Российской Федерации. Для получения подробной информации о реализуемых работах и услугах и их цене необходимо
          обращаться к менеджеру компании по контактам, указанным на сайте. Оставляя свои контактные данные на этом
          сайте, вы даете согласие на обработку своих персональных данных и соглашаетесь с политикой конфиденциальности
        </p>
        <Link to="/personal-data" className="footer__agreement-text-link">
          Политика конфиденциальности
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
