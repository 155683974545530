import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./slider.css";

import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import nextArrow from "../../images/icons/nextArrow.svg";
import prevArrow from "../../images/icons/prevArrow.svg";
import screenshot1 from "../../images/screenshots/screenshot1.png";
import screenshot2 from "../../images/screenshots/screenshot2.png";
import screenshot3 from "../../images/screenshots/screenshot3.png";
import screenshot4 from "../../images/screenshots/screenshot4.png";
import screenshot5 from "../../images/screenshots/screenshot5.png";
import { useState } from "react";
import { useEffect } from "react";

const Slider = () => {
  const dispatch = useDispatch();
  const pageWidth = useSelector((state) => state.width.pageWidth);
  const [scaleScreens, setScaleScreens] = useState(pageWidth >= 1440 ? 0.8 : 1);
  useEffect(() => {
    if (pageWidth >= 1440) setScaleScreens(0.8);
    else setScaleScreens(1);
  }, [pageWidth]);

  function handleOpenScreenPopup(e) {
    if (e.target.parentElement.className === "swiper-slide swiper-slide-visible swiper-slide-active") {
      dispatch({ type: "setScreenSrc", payload: e.target.src });
      dispatch({ type: "openScreenPopup", payload: true });
    }
  }

  return (
    <div className="container">
      <Swiper
        effect={"coverflow"}
        centeredSlides={true}
        followFinger={true}
        initialSlide={2}
        slidesPerView={`${pageWidth >= 1440 ? "5" : pageWidth >= 768 ? "3" : "1"}`}
        coverflowEffect={{
          scale: scaleScreens,
          rotate: 0,
          stretch: `${pageWidth >= 1440 ? "-50" : pageWidth >= 768 ? "-30" : "100"}`,
          depth: 100,
          modifier: 1,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container">
        <SwiperSlide onClick={handleOpenScreenPopup}>
          <img src={screenshot1} alt="slide_image" className="feedback__slider-image" />
        </SwiperSlide>
        <SwiperSlide onClick={handleOpenScreenPopup}>
          <img src={screenshot2} alt="slide_image" className="feedback__slider-image" />
        </SwiperSlide>
        <SwiperSlide onClick={handleOpenScreenPopup}>
          <img src={screenshot3} alt="slide_image" className="feedback__slider-image" />
        </SwiperSlide>
        <SwiperSlide onClick={handleOpenScreenPopup}>
          <img src={screenshot4} alt="slide_image" className="feedback__slider-image" />
        </SwiperSlide>
        <SwiperSlide onClick={handleOpenScreenPopup}>
          <img src={screenshot5} alt="slide_image" className="feedback__slider-image" />
        </SwiperSlide>
        <div className="slider-controler">
          {pageWidth >= 1440 ? (
            <>
              <div className="swiper-button-prev slider-arrow">
                <div className="background">
                  <img className="slider__arrow" src={prevArrow} alt="prevArrow" />
                </div>
              </div>
              <div className="swiper-button-next slider-arrow">
                <div className="background">
                  <img src={nextArrow} alt="nextArrow" />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
      <div className="slider__hover"></div>
      <div className="slider__hover slider__hover_left"></div>
    </div>
  );
};

export default Slider;
