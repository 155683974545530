import "./secondLines.css";
import { useSelector } from "react-redux";
const Line1 = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div className="second__line second__line1">
      {pageWidth >= 1440 ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="90" height="241" viewBox="0 0 90 241" fill="none">
          <path
            d="M0.5 240.5H0V241H0.5V240.5ZM0.5 3.50049V3.00049H0V3.50049H0.5ZM9.33333 3.50049C9.33333 4.97325 10.5272 6.16716 12 6.16716C13.4728 6.16716 14.6667 4.97325 14.6667 3.50049C14.6667 2.02773 13.4728 0.833822 12 0.833822C10.5272 0.833822 9.33333 2.02773 9.33333 3.50049ZM89.5 240H0.5V241H89.5V240ZM1 240.5V3.50049H0V240.5H1ZM0.5 4.00049H12V3.00049H0.5V4.00049Z"
            fill="#2FBAE5"
          />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="115" height="241" viewBox="0 0 115 241" fill="none">
          <path
            d="M0.5 240.5H0V241H0.5V240.5ZM0.5 3.50049V3.00049H0V3.50049H0.5ZM9.33333 3.50049C9.33333 4.97325 10.5272 6.16716 12 6.16716C13.4728 6.16716 14.6667 4.97325 14.6667 3.50049C14.6667 2.02773 13.4728 0.833822 12 0.833822C10.5272 0.833822 9.33333 2.02773 9.33333 3.50049ZM114.5 240H0.5V241H114.5V240ZM1 240.5V3.50049H0V240.5H1ZM0.5 4.00049H12V3.00049H0.5V4.00049Z"
            fill="#2FBAE5"
          />
        </svg>
      )}
    </div>
  );
};

export default Line1;
