import Line1 from "./line1";
import Line2 from "./line2";
import Line3 from "./line3";
import Third from "./third";
import { useSelector } from "react-redux";
const FindSvg = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div>
      {pageWidth >= 767 ? (
        <>
          <Third />
          <Line1 />
          <Line2 />
          <Line3 />
        </>
      ) : (
        <Third />
      )}
    </div>
  );
};

export default FindSvg;
