import "./secondLines.css";
import { useSelector } from "react-redux";
const Line2 = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div className="second__line second__line2">
      {pageWidth >= 1440 ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="113" height="98" viewBox="0 0 113 98" fill="none">
          <path
            d="M24.5 3.50049V3.00049H24.1121L24.0157 3.37618L24.5 3.50049ZM107.333 3.50049C107.333 4.97325 108.527 6.16716 110 6.16716C111.473 6.16716 112.667 4.97325 112.667 3.50049C112.667 2.02773 111.473 0.833822 110 0.833822C108.527 0.833822 107.333 2.02773 107.333 3.50049ZM0.9843 97.1243L24.9843 3.6248L24.0157 3.37618L0.0157002 96.8757L0.9843 97.1243ZM24.5 4.00049H110V3.00049H24.5V4.00049Z"
            fill="#2FBAE5"
          />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="167" height="84" viewBox="0 0 167 84" fill="none">
          <path
            d="M24.5 3.50049V3.00049H24.128L24.0211 3.35681L24.5 3.50049ZM161.333 3.50049C161.333 4.97325 162.527 6.16716 164 6.16716C165.473 6.16716 166.667 4.97325 166.667 3.50049C166.667 2.02773 165.473 0.833822 164 0.833822C162.527 0.833822 161.333 2.02773 161.333 3.50049ZM0.978913 83.6437L24.9789 3.64416L24.0211 3.35681L0.0210871 83.3563L0.978913 83.6437ZM24.5 4.00049H164V3.00049H24.5V4.00049Z"
            fill="#2FBAE5"
          />
        </svg>
      )}
    </div>
  );
};

export default Line2;
