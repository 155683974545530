const defaultStateCardPopup = { openCardPopup: false };

export const openCardReducer = (state = defaultStateCardPopup, action) => {
  switch (action.type) {
    case "openCard":
      return { ...state, openCardPopup: action.payload };
    case "closeCard":
      return { ...state, openCardPopup: action.payload };
    default:
      return state;
  }
};
