import "./thirdLines.css";
import { useSelector } from "react-redux";
const Line3 = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div className="third__line third__line3">
      {pageWidth >= 1440 ? (
        <svg width="415" height="132" viewBox="0 0 415 132" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.08249 131.5H414.002V0M1.57318 131.5L1.08249 32M0.591797 32.5H12.998H25.4042" stroke="#2FBAE5" />
          <circle cx="27.6465" cy="32.5" r="2.5" fill="#2FBAE5" />
        </svg>
      ) : (
        <svg width="483" height="185" viewBox="0 0 483 185" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M482 185V185.5H482.5V185H482ZM1 185H0.5V185.5H1V185ZM1 53.5V53H0.5V53.5H1ZM9.83333 53.5C9.83333 54.9728 11.0272 56.1667 12.5 56.1667C13.9728 56.1667 15.1667 54.9728 15.1667 53.5C15.1667 52.0272 13.9728 50.8333 12.5 50.8333C11.0272 50.8333 9.83333 52.0272 9.83333 53.5ZM482 184.5H1V185.5H482V184.5ZM1.5 185V53.5H0.5V185H1.5ZM1 54H12.5V53H1V54ZM482.5 185V0H481.5V185H482.5Z"
            fill="#2FBAE5"
          />
        </svg>
      )}
    </div>
  );
};

export default Line3;
