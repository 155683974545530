import "./secondLines.css";
import { useSelector } from "react-redux";
const Line3 = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div className="second__line second__line3">
      {pageWidth >= 1440 ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="508" height="147" viewBox="0 0 508 147" fill="none">
          <path
            d="M507.5 146.5V147H508V146.5H507.5ZM0.5 146.5H0V147H0.5V146.5ZM0.5 40.5V40H0V40.5H0.5ZM9.33333 40.5C9.33333 41.9728 10.5272 43.1667 12 43.1667C13.4728 43.1667 14.6667 41.9728 14.6667 40.5C14.6667 39.0272 13.4728 37.8333 12 37.8333C10.5272 37.8333 9.33333 39.0272 9.33333 40.5ZM507.5 146H0.5V147H507.5V146ZM1 146.5V40.5H0V146.5H1ZM0.5 41H12V40H0.5V41ZM508 146.5V0H507V146.5H508Z"
            fill="#2FBAE5"
          />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="508" height="140" viewBox="0 0 508 140" fill="none">
          <path
            d="M507.5 139.5V140H508V139.5H507.5ZM0.5 139.5H0V140H0.5V139.5ZM0.5 33.5V33H0V33.5H0.5ZM9.33333 33.5C9.33333 34.9728 10.5272 36.1667 12 36.1667C13.4728 36.1667 14.6667 34.9728 14.6667 33.5C14.6667 32.0272 13.4728 30.8333 12 30.8333C10.5272 30.8333 9.33333 32.0272 9.33333 33.5ZM507.5 139H0.5V140H507.5V139ZM1 139.5V33.5H0V139.5H1ZM0.5 34H12V33H0.5V34ZM508 139.5V0.5H507V139.5H508Z"
            fill="#2FBAE5"
          />
        </svg>
      )}
    </div>
  );
};

export default Line3;
