import { useSelector } from "react-redux";
import { Form } from "../form/form";
import PromoSvg from "../svg-images/first/promoSvg";
import "./promo.css";
import list from "../../images/icons/list.svg";

const Promo = ({ setIsLoading }) => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <section className="promo">
      {pageWidth >= 1440 ? (
        <div className="promo__container" id="form-promo">
          <div className="promo__image-and-text">
            <PromoSvg />
            <div className="promo__text-block promo__text-block1">
              <h6 className="promo__text-title">Любое импортное оборудование</h6>
              <p className="promo__text-text">материалы или комплектующие изделия «под заказ».</p>
            </div>
            <div className="promo__text-block promo__text-block2">
              <h6 className="promo__text-title">Полный сервис</h6>
              <p className="promo__text-text">от поиска до поставки под ключ</p>
            </div>
            <div className="promo__text-block promo__text-block3">
              <h6 className="promo__text-title">Эксперты в подборе</h6>
              <p className="promo__text-text">оборудования</p>
            </div>
            <div className="promo__text-block promo__text-block4">
              <h6 className="promo__text-title">Выгоднее на ~40%</h6>
              <p className="promo__text-text">чем покупка в Россиии</p>
            </div>
            <div className="promo__text-block promo__text-block5">
              <h6 className="promo__text-title">100% прозрачности</h6>
              <p className="promo__text-text">расчетов и наценки</p>
            </div>
          </div>

          <div className="promo__form">
            <Form setIsLoading={setIsLoading} />
          </div>
        </div>
      ) : pageWidth >= 768 ? (
        <>
          <div className="promo__container" id="form-promo">
            <div className="promo__image-and-text">
              <PromoSvg />
              <div className="promo__text-block promo__text-block1">
                <h6 className="promo__text-title">Любое импортное оборудование</h6>
                <p className="promo__text-text">материалы или комплектующие изделия «под заказ».</p>
              </div>
              <div className="promo__text-block promo__text-block2">
                <h6 className="promo__text-title">Полный сервис</h6>
                <p className="promo__text-text">от поиска до поставки под ключ</p>
              </div>
              <div className="promo__text-block promo__text-block3">
                <h6 className="promo__text-title">Эксперты в подборе</h6>
                <p className="promo__text-text">оборудования</p>
              </div>
              <div className="promo__text-block promo__text-block4">
                <h6 className="promo__text-title">Выгоднее на ~40%</h6>
                <p className="promo__text-text">чем покупка в Россиии</p>
              </div>
              <div className="promo__text-block promo__text-block5">
                <h6 className="promo__text-title">100% прозрачности</h6>
                <p className="promo__text-text">расчетов и наценки</p>
              </div>
            </div>
          </div>

          <div className="promo__form">
            <Form setIsLoading={setIsLoading} />
          </div>
        </>
      ) : (
        <>
          <div className="promo__container" id="form-promo">
            <div className="promo__image-and-text">
              <PromoSvg />
              <div className="promo__ul">
                <img src={list} alt="list" className="promo__li" />
                <div className="promo__text-block promo__text-block1">
                  <h6 className="promo__text-title">Любое импортное оборудование</h6>
                  <p className="promo__text-text">материалы или комплектующие изделия «под заказ».</p>
                </div>
              </div>
              <div className="promo__ul">
                <img src={list} alt="list" className="promo__li" />
                <div className="promo__text-block promo__text-block3">
                  <h6 className="promo__text-title">Эксперты в подборе</h6>
                  <p className="promo__text-text">оборудования</p>
                </div>
              </div>
              <div className="promo__ul">
                <img src={list} alt="list" className="promo__li" />
                <div className="promo__text-block promo__text-block4">
                  <h6 className="promo__text-title">Выгоднее на ~40%</h6>
                  <p className="promo__text-text">чем покупка в Россиии</p>
                </div>
              </div>
              <div className="promo__ul">
                <img src={list} alt="list" className="promo__li" />
                <div className="promo__text-block promo__text-block2">
                  <h6 className="promo__text-title">Полный сервис</h6>
                  <p className="promo__text-text">от поиска до поставки под ключ</p>
                </div>
              </div>
              <div className="promo__ul">
                <img src={list} alt="list" className="promo__li" />
                <div className="promo__text-block promo__text-block5">
                  <h6 className="promo__text-title">100% прозрачности</h6>
                  <p className="promo__text-text">расчетов и наценки</p>
                </div>
              </div>
            </div>
          </div>

          <div className="promo__form">
            <Form setIsLoading={setIsLoading} />
          </div>
        </>
      )}
    </section>
  );
};

export default Promo;
