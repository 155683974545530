import { useSelector } from "react-redux";
import { Link } from "react-scroll";
import { Button } from "../ui/button";
import "./doubts.css";

const Doubts = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <>
      <section className="doubts__header">
        <h2 className="doubts__header-title doubts-title__header-title">Вы станете нашим постоянным клиентом. 100%</h2>
        <h5 className="doubts__header-subtitle">
          Мы не бросаем клиентов после поставки и при возникновении каких-то вопросов мы способствуем их решению с
          поставщиком или производителем
        </h5>
        <div className="doubts-bottom__container">
          <Link
            className="promo__button"
            to="form-found"
            spy={true}
            smooth={true}
            offset={pageWidth >= 1440 ? -120 : pageWidth >= 768 ? 820 : 760}
            duration={700}>
            <Button type="button" typeButton="primary">
              <p>Оставить заявку</p>
            </Button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Doubts;
