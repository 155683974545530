import "./lines.css";
import { useSelector } from "react-redux";
const Line5 = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div className="line line5">
      {pageWidth >= 1440 ? (
        <svg width="30" height="236" viewBox="0 0 30 236" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.5 1V234M0 0.5H26.0224M0 233.5H26.0224" stroke="#2FBAE5" />
          <circle cx="26.5195" cy="233.5" r="2.5" fill="#2FBAE5" />
        </svg>
      ) : (
        <svg width="92" height="171" viewBox="0 0 92 171" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 0.5H0V0H0.5V0.5ZM0.5 167.5V168H0V167.5H0.5ZM9.33333 167.5C9.33333 166.027 10.5272 164.833 12 164.833C13.4728 164.833 14.6667 166.027 14.6667 167.5C14.6667 168.973 13.4728 170.167 12 170.167C10.5272 170.167 9.33333 168.973 9.33333 167.5ZM91.5 1H0.5V0H91.5V1ZM1 0.5V167.5H0V0.5H1ZM0.5 167H12V168H0.5V167Z"
            fill="#2FBAE5"
          />
        </svg>
      )}
    </div>
  );
};

export default Line5;
