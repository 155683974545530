import case1 from "../images/cases/case1.png";
import case2 from "../images/cases/case2.png";
import case3 from "../images/cases/case3.png";
import case4 from "../images/cases/case4.png";
import case5 from "../images/cases/case5.png";

export const data = [
  {
    id: 1,
    image: case1,
    product: "Вальцы для прокатки листового металла",
    buyer: "ООО “Невком”",
    city: "Санкт-Петербург",
    country: "Китай",
    time: "80 дней",
    cost: "50 000$",
    features: "Клиенту нужны вальцы, способные гнуть сталь до 60 мм. Таких предложений на российском рынке нет.",
    result:
      "Мы получили предложения от поставщиков из Китая и выбрали оптимального по соотношению цена/качество. В процессе закупки мы добавили в спецификацию необходимые устройства и успешно импортировали оборудование.",
  },
  {
    id: 2,
    product: "Станок лазерной резки Металла и источником Raycus 1,5KВт",
    image: case2,
    buyer: "Версия-центр",
    city: "Санкт-Петербург",
    country: "Китай",
    time: "80 дней",
    cost: "50 000$",
    features:
      "Клиент обратился к нам с предложением, которое он получил от китайского поставщика. Мы выяснили, что данное предложение содержит описание некачественных комплектующих, легкой рамы и серводвигателей меньшей мощности.",
    result:
      "Для клиента было подобрано необходимое оборудование с требуемыми техническими характеристиками. Оно было импортировано от проверенного производителя, с которым мы сотрудничаем много лет.",
  },
  {
    id: 3,
    product: "Токарный станок с ЧПУ",
    image: case3,
    buyer: "РПС",
    city: "Санкт-Петербург",
    country: "Китай",
    time: "80 дней",
    cost: "50 000$",
    features: "Заполнить",
    result:
      "В результате был подобран токарный станок большего размера с запасом. Кроме того, в комплект поставки были включены бесплатно расходные материалы в значительном количестве, а также дополнительные патроны, кулачки и люнеты.",
  },
  {
    id: 4,
    product: "Гибочный пресс",
    image: case4,
    buyer: "ООО “Версия-центр”",
    city: "Санкт-Петербург",
    country: "Китай",
    time: "80 дней",
    cost: "50 000$",
    features:
      "Заказчику требовалось изготовление свай настандартных размеров. Их особенность в том, что у них меньшая ширина лопасти, благодаря чему они легко вкручиваются. Такие сваи в настоящий момент набирают популярность, т.к. они удобнее для монтажников.",
    result:
      "В процессе сбора предложений поняли, что лучше выбрать полуавтоматическую линию.  Мы заключили контракт и успешно импортировали оборудование.",
  },
  {
    id: 5,
    product: "Станок электроэрозионный проволочно вырезной",
    image: case5,
    buyer: "ООО “Версия-центр”",
    city: "Санкт-Петербург",
    country: "Китай",
    time: "80 дней",
    cost: "50 000$",
    features: "В процессе железнодорожной перевозки станок получил повреждения.",
    result:
      "После ряда переговоров с поставщиком, он бесплатно осуществил поставку поврежденных деталей. Также мы помогли клиенту получить необходимые инструкции для замены этих деталей. По итогу клиент получил полностью рабочее оборудование.",
  },
];
