const defaultScreenSrc = { src: ''};

export const screenSrcReducer = (state = defaultScreenSrc, action) => {
  switch (action.type) {
    case "setScreenSrc":
      return { ...state, src: action.payload };
    default:
      return state;
  }
};
