import { forwardRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./button.css";

export const Button = forwardRef(({ children, typeButton, isDisabled }, ref) => {
  const [buttonType, setButtonType] = useState("button_primary");

  useEffect(() => {
    switch (typeButton) {
      case "primary":
        setButtonType("button_primary");
        break;
      case "file":
        setButtonType("button_file");
        break;
      default:
        setButtonType("button_primary");
    }
  }, [typeButton]);

  return (
    <button ref={ref} className={`button ${buttonType}`}>
      {children}
    </button>
  );
});

export const MButton = motion(Button);
