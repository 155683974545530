import Spinner from "../form/spinner/spinner";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Number from "./number/number";
import "./popup.css";

const NumberPopup = () => {
    const isOpen = useSelector((state) => state.number.openNumberPopup);
    const dispatch = useDispatch();
    const setPopupClose = () => {
        dispatch({ type: "closeNumberPopup", payload: false });
      };
    const [isLoading, setIsLoading] = useState(false);
    return (
        <>
            <div className={isOpen ? "popup popup_opened" : "popup"}>
                <Number onClose={setPopupClose} setIsLoading={setIsLoading} />
            </div>
            <Spinner openSpinner={isLoading} />
        </>
    );
};

export default NumberPopup;
