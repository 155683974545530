import { useDispatch, useSelector } from "react-redux";
import closePopup from "../../../images/closePopup.svg";
import "./feedbackPopup.css";

const FeedbackPopup = () => {
  const dispatch = useDispatch();
  const openScreenPopup = useSelector((state) => state.screen.openScreenPopup);
  const screenSrc = useSelector((state) => state.src.src);
  const setPopupClosed = () => {
    dispatch({ type: "closeScreenPopup", payload: false });
  };

  return (
    <div className={openScreenPopup ? "feedbackPopup feedbackPopup_opened" : "feedbackPopup"}>
      <button onClick={setPopupClosed} className="feedbackPopup__overlay" />
      <div className="feedbackPopup__container">
        <>
          <img src={screenSrc} alt="screenshot" className="feedbackPopup__image"/>
          <button onClick={setPopupClosed} className="feedbackPopup__close" type="button">
            <img src={closePopup} alt="closePopup" />
          </button>
        </>

      </div>
    </div>
  );
};

export default FeedbackPopup;
