import closePopup from "../../../images/closePopup.svg";
import "./table.css";

const Table = ({ onClose }) => {
  return (
    <div>
      <button onClick={onClose} className="detail__overlay" />
      <div className="detail__container">
        <h4 className="detail__title">Детализация расчётов</h4>
        <button onClick={onClose} className="detail__close" type="button">
          <img src={closePopup} alt="closePopup" />
        </button>
        <ul className="detail__list">
          <li className="detail__price">
            <p className="detail__text">Стоимость товара</p>
            <hr className="detail__line detail__line_1" />
            <p className="detail__text detail__text_bold">5 900 $</p>
          </li>
          <li className="detail__price">
            <p className="detail__text">НДС</p>
            <hr className="detail__line detail__line_2" />
            <p className="detail__text">1340 $</p>
          </li>
          <li className="detail__price">
            <p className="detail__text">Сборы</p>
            <hr className="detail__line detail__line_3" />
            <p className="detail__text">135 $</p>
          </li>
          <li className="detail__price">
            <p className="detail__text">Банковские расходы</p>
            <hr className="detail__line detail__line_4" />
            <p className="detail__text">80 $</p>
          </li>
          <li className="detail__price">
            <p className="detail__text">Таможенное оформление</p>
            <hr className="detail__line detail__line_5" />
            <p className="detail__text">200 $</p>
          </li>
          <li className="detail__price">
            <p className="detail__text">Транспортные расходы</p>
            <hr className="detail__line detail__line_6" />
            <p className="detail__text">1 300 $</p>
          </li>
        </ul>
        <div className="detail__price detail__price_contract">
          <p className="detail__text">Итого по контракту</p>
          <p className="detail__text detail__text_bold">8 955 $</p>
        </div>
        <ul className="detail__list">
          <li className="detail__price">
            <p className="detail__text">Наши услуги</p>
            <hr className="detail__line detail__line_7" />
            <p className="detail__text detail__text_bold">1 500 $</p>
          </li>
        </ul>
        <div className="detail__price detail__price_contract">
          <h2 className="detail__summary">Итого</h2>
          <h2 className="detail__summary">10 455 $</h2>
        </div>
        <div className="detail__bottom">
          <p className="detail__bottom-text">Включая услуги.</p>
          <p className="detail__bottom-text">10% от стоимости контракта, но не менее 1 500 $</p>
        </div>
      </div>
    </div>
  );
};

export default Table;
