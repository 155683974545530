import "./lines.css";
import { useSelector } from "react-redux";
const Line3 = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div className="line line3">
      {pageWidth >= 1440 ? (
        <svg width="107" height="102" viewBox="0 0 107 102" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M76.7887 2.1748L39.7568 101.175M76.3203 2.49989H104.5M0.257812 101.5H40.2923" stroke="#2FBAE5" />
          <circle cx="104.5" cy="2.5" r="2.5" fill="#2FBAE5" />
        </svg>
      ) : (
        <svg width="165" height="42" viewBox="0 0 165 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.5013 3.5V3H10.1172L10.0182 3.37115L10.5013 3.5ZM159.333 3.5C159.333 4.97276 160.527 6.16667 162 6.16667C163.473 6.16667 164.667 4.97276 164.667 3.5C164.667 2.02724 163.473 0.833333 162 0.833333C160.527 0.833333 159.333 2.02724 159.333 3.5ZM10.5013 4H162V3H10.5013V4ZM0.983113 41.1288L10.9844 3.62885L10.0182 3.37115L0.0168867 40.8712L0.983113 41.1288Z"
            fill="#2FBAE5"
          />
        </svg>
      )}
    </div>
  );
};

export default Line3;
