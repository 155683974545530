import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-dadata/dist/react-dadata.css";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { FioSuggestions, EmailSuggestions } from "react-dadata";
import { Link } from "react-router-dom";
import axios from "axios";
import delFile from "../../images/delFile.svg";
import "./form.css";
import { useSelector } from "react-redux";
import loadFile from "../../images/icons/loadFile.svg";

export const Form = ({ setIsLoading, testId }) => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  const indexFile = useSelector((state) => state.index.index);
  const dispatch = useDispatch();
  const [isValidForm, setIsValidForm] = useState(false);
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [checked, setChecked] = useState(true);
  const [files, setFiles] = useState([]);
  const [filesSizeValid, setFilesSizeValid] = useState(true);
  const maxFilesSize = 10;
  const addedFiles = [];
  let counter = 1;

  const setPopupOpened = () => {
    dispatch({ type: "openPopup", payload: true });
  };
  const sendSuccess = () => {
    dispatch({ type: "sendSuccess", payload: true });
  };
  const sendError = () => {
    dispatch({ type: "sendError", payload: false });
  };
  function handleChangeFiles(e) {
    if (!e.target.files.length) {
      return;
    }
    const arr = Array.from(e.target.files);
    if (e.target.files.length === 1) {
      dispatch({ type: "add_index", payload: 1 });
      arr[0]._id = indexFile;
      addedFiles.push(arr[0]);
    } else {
      for (let i = 0; i < arr.length; i++) {
        counter++;
        arr[i]._id = indexFile + counter;
        addedFiles.push(arr[i]);
      }
      dispatch({ type: "add_index", payload: counter + 1 });
    }
    setFiles(files.concat(addedFiles));
  }
  useEffect(() => {
    if (email && name) {
      setIsValidForm(email.value && phone.length > 10 && name.value && checked && filesSizeValid);
    }
  }, [name, email, phone, checked, filesSizeValid]);
  useEffect(() => {
    let filesSize = 0;
    files.forEach((file) => (filesSize = filesSize + file.size / 1024 / 1024));
    if (filesSize < maxFilesSize) {
      setFilesSizeValid(true);
    } else {
      setFilesSizeValid(false);
    }
  }, [files]);
  const handleDeleteFile = (file) => {
    setFiles(files.filter((data) => data._id !== file._id));
  };
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name.value);
    formData.append("email", email.value);
    formData.append("phone", phone);
    formData.append("host", "maximum-logistic.ru");
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("comment", comment);
    try {
      await axios.post("https://maximum-logistics.ru/api/v1/orders/", formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      setPopupOpened();
      setIsLoading(false);
      setFiles([]);
      sendSuccess();
      dispatch({ type: "set_index", payload: 0 });
    } catch (error) {
      setPopupOpened();
      setIsLoading(false);
      sendError();
      setFiles([]);
    }
  };

  return (
    <form onSubmit={handleSubmit} id="form" className="form">
      <div className="form__container">
        <h4 className="form__title">Оставьте заявку на импорт</h4>
        <div className="form__subtitle">Заполните форму, перезвоним в течение 15 минут, чтобы уточнить детали</div>
        <div className="input__container">
          <FioSuggestions
            inputProps={{
              placeholder: "Ваше имя",
              required: true,
            }}
            selectOnBlur="true"
            containerClassName="form__input"
            id="name"
            name="name"
            type="NAME"
            token="469678a76f51fa567ff874967776f95d2082fdfc"
            value={name}
            onChange={setName}
          />
          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
              placeholder: "Телефон",
            }}
            disableDropdown
            disableSearchIcon
            enableAreaCodes={false}
            onlyCountries={["ru"]}
            id="phone"
            type="text"
            inputClass="form__input-phone"
            buttonClass="form__input-phone-button"
            country={"ru"}
            value={phone}
            onChange={setPhone}
            isValid={(value) => {
              if (!value) {
                return `Invalid value`;
              }
              return true;
            }}
          />
          <EmailSuggestions
            selectOnBlur="true"
            containerClassName="form__input"
            id="email"
            name="email"
            type="email"
            token="469678a76f51fa567ff874967776f95d2082fdfc"
            value={email}
            onChange={setEmail}
            inputProps={{
              placeholder: "Электронная почта",
              required: true,
            }}
          />
          <textarea
            placeholder="Что необходимо подобрать и привезти"
            className="form__textarea"
            name="textarea"
            id="textarea"
            cols="30"
            rows={pageWidth >= 1440 ? "5" : "4"}
            onChange={(e) => setComment(e.target.value)}></textarea>
        </div>
        <div className={files?.length ? "form__added form__added_active" : "form__added"}>
          {files.map((file) => {
            return (
              <div className="form__selected" key={file._id}>
                <p className="form__added-text">{file.name}</p>
                <button className="form__delete-file" type="button" onClick={() => handleDeleteFile(file)}>
                  <img src={delFile} alt="delete file" />
                </button>
              </div>
            );
          })}
        </div>
        <p className={!filesSizeValid ? "form__size_active" : "form__size"}>
          Размер файлов не должен превышать {maxFilesSize} Мбайт
        </p>
        <button className="button__file button__file_tablet" type="button">
          <input
            className="form__add-file-input"
            type="file"
            multiple
            onChange={handleChangeFiles}
            accept="image/*, .pdf, .xlsx, .xls, .png, .jpg, .doc, .docx, .txt, .ai, .dwg, .dwt, .dxf, .psd"
          />
          <img src={loadFile} alt="loadFileIcon" />
          <p className="form__button-text form__button-text_tablet">Прикрепить файл</p>
        </button>
        <button type="submit" className="button button_tablet" disabled={!isValidForm}>
          <p className="form__button-text">Оставить заявку</p>
        </button>
        <div className="form__text form__text_agreement">
          Нажимая кнопку “Оставить заявку” вы соглашаетесь с&nbsp;
          <Link to="/personal-data" data-testid={testId} className="form__agreement-text-link">
            политикой конфиденциальности
          </Link>
        </div>
      </div>
    </form>
  );
};
