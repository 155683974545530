const defaultStateScreenPopup = { openCardPopup: false };

export const openScreenReducer = (state = defaultStateScreenPopup, action) => {
  switch (action.type) {
    case "openScreenPopup":
      return { ...state, openScreenPopup: action.payload };
    case "closeScreenPopup":
      return { ...state, openScreenPopup: action.payload };
    default:
      return state;
  }
};
