import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-dadata/dist/react-dadata.css";
import { FioSuggestions } from "react-dadata";
import { useDispatch } from "react-redux";
import closePopup from "../../../images/closePopup.svg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./number.css";

const Number = ({ setIsLoading }) => {
  const [isValidForm, setIsValidForm] = useState(false);
  const [name, setName] = useState();
  const [phone, setPhone] = useState("");
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  const setPopupOpened = () => {
    dispatch({ type: "closeNumberPopup", payload: false });
    dispatch({ type: "openPopup", payload: true });
  };
  const setPopupClose = () => {
    dispatch({ type: "closeNumberPopup", payload: false });
  };

  const sendSuccess = () => {
    dispatch({ type: "sendSuccess", payload: true });
  };

  const sendError = () => {
    dispatch({ type: "sendError", payload: false });
  };

  useEffect(() => {
    if (name) {
      setIsValidForm(phone.length > 10 && name.value);
    }
  }, [name, phone]);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name.value);
    formData.append("email", "example@mail.ru");
    formData.append("phone", phone);
    formData.append("host", "maximum-logistic.ru");
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("comment", "comment");
    try {
      const res = await axios.post("https://maximum-logistics.ru/api/v1/orders/", formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      setPopupOpened();
      setIsLoading(false);
      sendSuccess();
    } catch (error) {
      setPopupOpened();
      setIsLoading(false);
      sendError();
    }
  };

  return (
    <div>
      <button onClick={setPopupClose} className="popup-form__overlay" />
      <form className="popup-form" onSubmit={handleSubmit}>
        <div className="popup-form__container">
          <h4 className="popup-form__title">Перезвоните мне</h4>
          <div className="popup-form__subtitle">
            Заполните форму, перезвоним в течение 15 минут, чтобы уточнить детали
          </div>
          <button onClick={setPopupClose} className="popup-form__close" type="button">
            <img src={closePopup} alt="closePopup" />
          </button>
          <FioSuggestions
            inputProps={{
              placeholder: "Ваше имя",
              required: true,
            }}
            selectOnBlur="true"
            containerClassName="popup-form__input"
            id="name"
            name="name"
            type="NAME"
            token="469678a76f51fa567ff874967776f95d2082fdfc"
            value={name}
            onChange={setName}
          />
          <div className="popup-form__input-phone">
            <PhoneInput
              inputProps={{
                name: "phone",
                required: true,
                placeholder: "Телефон",
              }}
              disableDropdown
              disableSearchIcon
              enableAreaCodes={false}
              onlyCountries={["ru"]}
              id="phone"
              type="text"
              inputClass="form__input-phone"
              buttonClass="form__input-phone-button"
              country={"ru"}
              value={phone}
              onChange={setPhone}
              isValid={(value) => {
                if (!value) {
                  return `Invalid value`;
                }
                return true;
              }}
            />
          </div>

          <button type="submit" className="button button_tablet" disabled={!isValidForm}>
            <p className="form__button-text">Оставить заявку</p>
          </button>
          <div className="form__text form__text_agreement">
            Нажимая кнопку “Оставить заявку” вы соглашаетесь с&nbsp;
            <Link to="/personal-data" className="form__agreement-text-link" onClick={setPopupClose}>
              политикой конфиденциальности
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Number;
