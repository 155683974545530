import economy from "../../images/largeImages/economy_image.png";
import { useSelector } from "react-redux";
import "./economy.css";

const Economy = ({ isOpen }) => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <section className="economy">
      {pageWidth > 768 ? (
        <div className="economy__container">
          <div className="economy__column">
            <img src={economy} alt="economy" className="economy__image" />
          </div>
          <div className="economy__column">
            <h2 className="economy__title">10% ОТ СУММЫ КОНТРАКТА</h2>
            <h2 className="economy__title">Почему это выгодно для вас?</h2>
            <p className="economy__text">
              Вот пример, как мы сэкономили для клиента 12 700$ на поставке системы лазерной сварки OREE LASER.
            </p>
            <div className="economy__table">
              <div className="economy__table-column">
                <p className="economy__table-title">Покупка с наценкой в РФ</p>
                <h4 className="economy__table-usd">21 700 USD</h4>
              </div>
              <div className="economy__table-column">
                <p className="economy__table-title">Наш расчет</p>
                <h4 className="economy__table-usd  economy__table-usd_blue">8 900 USD</h4>
              </div>
            </div>
            <h2 className="economy__title">ДЕШЕВЛЕ НА 12 700 USD</h2>
            <button className="economy__button" type="button" onClick={isOpen}>
              <p className="economy__button-text">Смотреть детализацию</p>
            </button>
          </div>
        </div>
      ) : (
        <div className="economy__container">
          <div className="economy__column">
            <div className="economy__column">
              <img src={economy} alt="economy" className="economy__image" />
            </div>
            <h2 className="economy__title">10% ОТ СУММЫ КОНТРАКТА</h2>
            <h2 className="economy__title">Почему это выгодно для вас?</h2>
            <p className="economy__text">
              Вот пример, как мы сэкономили для клиента 12 700 $ на поставке системы лазерной сварки OREE LASER.
            </p>
            <div className="economy__table">
              <div className="economy__table-column">
                <p className="economy__table-title">
                  <span>Покупка с наценкой в РФ</span>
                </p>
                <h4 className="economy__table-usd">21 700 USD</h4>
              </div>
              <div className="economy__table-column">
                <p className="economy__table-title">Наш расчет</p>
                <h4 className="economy__table-usd economy__table-usd_blue">8 955 USD</h4>
              </div>
            </div>
            <h2 className="economy__title">ДЕШЕВЛЕ НА 12 700 USD</h2>
            <button className="economy__button" type="button" onClick={isOpen}>
              <p className="economy__button-text">Смотреть детализацию</p>
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Economy;
