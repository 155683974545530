import { Form } from "../form/form";
import "./found.css";
import FindSvg from "../svg-images/third/findSvg";
import list from "../../images/icons/list.svg";
import { useSelector } from "react-redux";
const Found = ({ setIsLoading }) => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <section className="found" id="form-found">
      <h2 className="found__title">Уже нашли оборудование самостоятельно?</h2>
      <div className="found__container">
        <div className="found__column_one">
          <FindSvg />
          {pageWidth > 767 ? (
            <>
              <div className="found__absolute">
                <h6 className="found__absolute-title found__absolute-title1">Проведем оценку выбранного поставщика</h6>
                <p className="found__absolute-text">и подскажем, где вас могли ввести в заблуждение</p>
              </div>
              <div className="found__absolute">
                <h6 className="found__absolute-title">Сделаем расчет</h6>
                <p className="found__absolute-text">
                  стоимости импорта оборудования на основе предоставленных Вами документов
                </p>
              </div>
              <div className="found__absolute">
                <h6 className="found__absolute-title found__absolute-title3">
                  Проанализируем рынок и соберем предложения
                </h6>
                <p className="found__absolute-text">на аналогичный товар / оборудование от других производителей</p>
              </div>
            </>
          ) : (
            <>
              <div className="seller__list">
                <img src={list} alt="list" className="seller__li" />
                <div className="found__absolute">
                  <h6 className="found__absolute-title found__absolute-title1">
                    Проведем оценку выбранного поставщика
                  </h6>
                  <p className="found__absolute-text">и подскажем, где вас могли ввести в заблуждение</p>
                </div>
              </div>
              <div className="seller__list">
                <img src={list} alt="list" className="seller__li" />
                <div className="found__absolute">
                  <h6 className="found__absolute-title">Сделаем расчет</h6>
                  <p className="found__absolute-text">
                    стоимости импорта оборудования на основе предоставленных Вами документов
                  </p>
                </div>
              </div>
              <div className="seller__list">
                <img src={list} alt="list" className="seller__li" />
                <div className="found__absolute">
                  <h6 className="found__absolute-title found__absolute-title3">
                    Проанализируем рынок и соберем предложения
                  </h6>
                  <p className="found__absolute-text">на аналогичный товар / оборудование от других производителей</p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="found__column">
          <Form setIsLoading={setIsLoading} />
        </div>
      </div>
    </section>
  );
};

export default Found;
