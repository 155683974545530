import "./map.css";
import { useSelector } from "react-redux";
const Map = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div className="map">
      <iframe
        className="map__content"
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A24a00f668189c7dc5ef84b2c4ca4aa6ec4634118a784c9e430cf151e5cfb97e4&amp;source=constructor"
        width={pageWidth >= 1440 ? "768" : "720"}
        height="320"
        frameBorder="0"
        title="Карта"
      />
    </div>
  );
};

export default Map;
