import Second from "./second";
import Line1 from "./line1";
import Line2 from "./line2";
import Line3 from "./line3";
import { useSelector } from "react-redux";
const PromoSvg = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div>
      {pageWidth >= 767 ? (
        <>
          <Second />
          <Line1 />
          <Line2 />
          <Line3 />
        </>
      ) : (<Second />)
      }
    </div>
  );
};

export default PromoSvg;
