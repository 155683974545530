import exclude from "../../images/icons/variant_exclude_tablet.svg";
import include from "../../images/icons/variant_include_tablet.svg";
import { useSelector } from "react-redux";
import "./variants.css";

const Variants = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <section className="variants">
      <div className="variants__container">
        <h2 className="variants__title">ВАРИАНТЫ СОТРУДНИЧЕСТВА</h2>
        <div className="variants__cards">
          <div className="variants__card">
            <h4 className="variants__card-title">Импорт оборудование на вашу компанию</h4>
            <div className="variants__card-paragraphs">
              <div className="variants__card-text">
                <img src={include} alt="include" />
                <p className="variants__text">
                  Контракт заключается от вашего лица и вы становитесь полноценным участником ВЭД.
                </p>
              </div>
              <div className="variants__card-text">
                <img src={include} alt="include" />
                <p className="variants__text">Всю работу по организации импорта мы берем на себя.</p>
              </div>
              <div className="variants__card-text">
                <img src={exclude} alt="include" />
                <p className="variants__text">Вы самостоятельно делаете все платежи</p>
              </div>
              <div className="variants__card-text">
                <img src={exclude} alt="include" />
                <p className="variants__text">Более высокая комиссия</p>
              </div>
            </div>
            <p className="variants__card-bottom-text">Наша комиссия составит</p>
            <h4 className="variants__card-bottom">15% от стоимости контракта*</h4>
          </div>
          <div className="variants__card">
            <h4 className="variants__card-title">Покупка оборудования у нашей организации по договору поставки</h4>
            <div className="variants__card-paragraphs">
              <div className="variants__card-text">
                <img src={include} alt="include" />
                <p className="variants__text">
                  Берем на себя все обязанности и ответственность по осуществлению импорта на свою компанию
                </p>
              </div>
              <div className="variants__card-text">
                <img src={include} alt="include" />
                <p className="variants__text">
                  Поставленный товар освобожден от ответственности перед органами валютного, таможенного и налогового
                  контроля
                </p>
              </div>
              <div className="variants__card-text">
                <img src={include} alt="include" />
                <p className="variants__text">
                  Вы покупаете оборудование у нашей компании по договору поставки, получаете его на оптимальных, заранее
                  обговоренных условиях и в срок.
                </p>
              </div>
              <div className="variants__card-text">
                <img src={include} alt="include" />
                <p className="variants__text">Более низкая комиссия</p>
              </div>
            </div>
            <p className="variants__card-bottom-text">Наша комиссия составит</p>
            <h4 className="variants__card-bottom">10% от стоимости контракта*</h4>
          </div>
        </div>
        <p className="variants__bottom-text">
          *Указаны условия для контрактов общей стоимостью до 50 000$, комиссия для контрактов превышающие данную сумму
          - обсуждается в частном порядке
        </p>
      </div>
    </section>
  );
};

export default Variants;
