import "./lines.css";
import { useSelector } from "react-redux";
const Line4 = () => {
  const pageWidth = useSelector((state) => state.width.pageWidth);
  return (
    <div className="line line4">
      {pageWidth >= 1440 ? (
        <svg width="87" height="156" viewBox="0 0 87 156" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M56.7965 0.171387L0.644531 153.888M56.3267 0.500088H86.3526M1.27986 153.5H74.4986"
            stroke="#2FBAE5"
          />
          <circle cx="75.5" cy="153.5" r="2.5" fill="#2FBAE5" />
        </svg>
      ) : (
        <svg width="184" height="158" viewBox="0 0 184 158" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M178.333 154.5C178.333 155.973 179.527 157.167 181 157.167C182.473 157.167 183.667 155.973 183.667 154.5C183.667 153.027 182.473 151.833 181 151.833C179.527 151.833 178.333 153.027 178.333 154.5ZM1.5 154.5L1.07608 154.235L0.597565 155H1.5V154.5ZM97.5 1V0.5H97.223L97.0761 0.734876L97.5 1ZM181 154H1.5V155H181V154ZM1.92392 154.765L97.9239 1.26512L97.0761 0.734876L1.07608 154.235L1.92392 154.765ZM97.5 1.5H150V0.5H97.5V1.5Z"
            fill="#2FBAE5"
          />
        </svg>
      )}
    </div>
  );
};

export default Line4;
