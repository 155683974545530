import feedback from "../../images/icons/feedback.svg";
import React from "react";

import "./feedback.css";
import Slider from "./slider";

const Feedback = ({ isOpen }) => {
  return (
    <section className="feedback">
      <div className="feedback__container">
        <h2 className="feedback__title">Убедитесь в прозрачности и честности поставки</h2>
        <h5 className="feedback__subtitle">
          Для каждой поставки мы создаем чат WhatsApp, в котором находитесь вы, ваш персональный менеджер и поставщик
          оборудования. В данном чате вы сможете оперативно уточнить любую информацию, а также следить за переговорами,
          условиями и ходом поставки.
        </h5>
      </div>
      <div className="feedback__slider">{isOpen ? <></> : <Slider />}</div>
      <div className="feedback__garanty">
        <div className="feedback__garanty-item">
          <img src={feedback} alt="feedback svg" />
          <h5 className="feedback__garanty-item-title">Мы не скрываем поставщиков</h5>
        </div>
        <div className="feedback__garanty-item">
          <img src={feedback} alt="feedback svg" />
          <h5 className="feedback__garanty-item-title">Все переговоры при вашем участии</h5>
        </div>
        <div className="feedback__garanty-item">
          <img src={feedback} alt="feedback svg" />
          <h5 className="feedback__garanty-item-title">Отсутствуют скрытые платежи и наценки</h5>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
